export default () => {
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    if (window.location.origin) {
      return window.location.origin;
    }
    return `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
  }
  return '';
};
