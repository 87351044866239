class NotFoundError extends Error {
  identifier: string;
  error: any;
  constructor(e: any) {
    super('NOT_FOUND');
    this.error = e;
    this.identifier = 'NOT_FOUND';
  }
}

export default NotFoundError;
