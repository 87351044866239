export default function(props: { hostname?: string }) {
  let domain = '';
  if (props && props.hostname) {
    domain = props.hostname;
  }
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    domain = window.location.hostname;
  }
  return domain.replace(/\.(localhost|127\.0\.0\.1|::1)$/, '');
}
